<template>
  <section class="invoice-preview-wrapper">

    <b-row

      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >

          <b-row class="p-1">
            <b-col sm="12" md="7">
              <!-- Booking ID
              <h5> {{ invoiceData.detail.id }}</h5> -->
              Client Name 顧客姓名
              <h5> {{invoiceData.user.zh_name }} {{invoiceData.user.en_name }} ({{invoiceData.user.member_code }})</h5><br>
              Redeem Date Time 使用日期及時間
              <h5>{{ invoiceData.detail.start }} To {{ invoiceData.detail.end }}</h5><br>
              Staff Name 美容師
              <h5>{{ invoiceData.detail.staff_name }}</h5><br>
              Package name 療程名稱
              <v-select
                    v-model="invoiceData.selected_package"
                    :reduce="option => option.id"
                    :options="packageOptions"
                    :clearable="false"
                    input-id="status"
                    :disabled="onetime_pay"
                  />
                  <br>
              Redeem Quantity
              <b-form-spinbutton
                id="demo-sb"
                v-model="credit"
                size="lg"
                min="1"
                max="5"
                :disabled="onetime_pay"
              />
             
            <b-col sm="6" class="p-0">
            <b-button
            class="mt-2"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            @click="onetime_pay = true"
            v-if="invoiceData && invoiceData.detail.signature  === null"
            variant="primary"
          >
            即時付款
          </b-button>
          
        </b-col>
        <div v-if="onetime_pay === true">
          <hr />
          Package name 療程名稱
              <v-select
                    v-model="onetime_selected_package"
                    :options="onetime_package"
                    label="name"
                    :clearable="false"
                    input-id="status"
                  />
               
                  <b-form-group label="Amount 金額" class="mt-1"
                  label-for="name">
                  <b-input-group
                    
                    prepend="$"
  
                    class="input-group-merge"
                  >
      <b-form-input placeholder="100" v-model="onetime_amount"/>
    </b-input-group>
    <hr class="my-2">
                        <label class="section-label mb-1">Payment Details</label>

                       
                            <b-form-group label="Payment Method" label-for="payment-method" >
                                <v-select v-model="payment.selected_payment_method" :reduce="val => val.value" :options="paymentMethods" :clearable="false" input-id="paymentMethod"/>
                                
                            </b-form-group>
                      
                            <b-form-group label="Payment Amount" label-for="payment_amount">
                                <b-form-input id="payment_amount" v-model="payment.payment_amount" trim/>

                               
                            </b-form-group>


                    <hr class="my-2"/>
                    <!-- Form Actions -->
                    <div v-for="(payment, index) in payment.payment_method">
                        {{payment.method}}
                        - $
                        {{payment.amount}}
                    </div>
                    <h4>Total :
                        {{total}}</h4>
                    <div class="mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="button" block @click="add_payment()" :disabled="show">
                            Add Payment
                        </b-button>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" variant="outline-warning" block @click="clear_payment()">
                            Clear Payment
                        </b-button>

                        <!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="$router.push(`/epad/6/`)" > GO </b-button> -->

                    </div>
  </b-form-group>
        </div>
            </b-col>
            <b-col  sm="12" md="5" style="border-left:1px solid #fff;">
              <VueSignaturePad
                v-if="invoiceData && invoiceData.detail.signature  === null"
                ref="signaturePad"
                :options="{ dotSize: 2, throttle: 16,backgroundColor: '#efefef',onBegin, onEnd }"
              />
              <img v-if="invoiceData && invoiceData.detail.signature  !== null" :src="invoiceData.detail.signature" style="width:100%">
            </b-col>
          </b-row>
          <hr class="invoice-spacing">
              <b-card-body class="invoice-padding pt-0">

                 <b-row class="mt-2">
                  <b-col  sm="12" md="9">
                    <div class="font-weight-bold text-center mb-2">TERMS AND CONDITIONS</div>
                    <nl2br class="tnc" tag="div" :text="invoiceData.terms" />

            </b-col>
            <b-col sm="12" md="3">
                <b-button
                class="mb-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                @click="save()"
                v-if="invoiceData && invoiceData.detail.signature  === null"
                variant="primary"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                class="mb-1"
                block
                @click="clear()"
                v-if="invoiceData && invoiceData.detail.signature  === null"
                variant="outline-warning"
              >
                Empty
    </b-button>
    <b-button block class="mb-1" variant="outline-secondary" :to="'/calendar'">Back</b-button>
            </b-col>
            </b-row>

             </b-card-body>
        </b-card>
      </b-col>
    </b-row>

      <div>
     <vue-html2pdf

        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="10000"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"

        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <b-row

              class="invoice-preview"
            >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
            </div>
          </b-card-body>
          </b-card>


          <hr class="invoice-spacing">
              <b-card-body class="invoice-padding pt-0">
                <div class="font-weight-bold text-center mb-2">TERMS AND CONDITIONS</div>
                <nl2br class="tnc" tag="div" :text="invoiceData.terms"  style="font-size:8px;"/>
             </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <img :src="signature" />
    </section>
    </vue-html2pdf>
   </div>



  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BCardBody,BFormSpinbutton, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,BForm, BFormInput,BFormTextarea,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroupAppend
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import store from '@/store'
import Nl2br from 'vue-nl2br'
import VueSignaturePad from 'vue-signature-pad';
import VueHtml2pdf from 'vue-html2pdf'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    VueHtml2pdf,
    BRow,
    vSelect,
    BFormSpinbutton,
    ValidationProvider, ValidationObserver,
    BForm, BFormGroup, BFormInput,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    Nl2br,
    ToastificationContent,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    Logo,
  },
  computed: {
  
    total() {
      let total = 0
      this.payment.payment_method.forEach((item, i) => {
        total = total + parseInt(item.amount)
      });
      return total
    },
  },
  data() {
    return {
      invoiceData : {
      },
      credit:1,
      onetime_pay : true,
      onetime_amount : null,
      onetime_selected_package : {},
      items:[],
      onetime_package: [],
      signature:null,
      packageOptions:[],
      paymentMethods:[],
      payment: {
        payment_method:[],
        payment_amount:null,
        selected_payment_method:null
      },
      fields:['package_name','package_type','quantity','expire_at']
    }
  },
  watch: {
    onetime_selected_package: {
      handler(newValue, oldValue) {
        this.onetime_amount = this.onetime_selected_package.price
      },
    },
  },
  created() {
    this.get_pt_data()
    this.get_paymentMethods()
  },
  methods : {
    async add_payment() {
      if (this.payment.payment_amount > this.onetime_amount ) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: '金額大於選擇項目',
          },
        })
        return
      }
      switch(this.payment.selected_payment_method) {
        case "STRIPE":
        // this.show = true
        // this.card.amount = this.packageData.payment_amount
        // this.card.member_id = this.userData.member_id
        // if (this.installment.enabled === true) {
        //   if (this.packageData.package.has_commission == 'Y' && this.commission.length < 1 ) {
        //     this.$toast({
        //       component: ToastificationContent,
        //       position: 'top-center',
        //       props: {
        //         title: 'Error',
        //         icon: 'XIcon',
        //         variant: 'danger',
        //         text: "請先輸入佣金比例",
        //       },
        //     })
        //     this.show = false
        //     return
        //   }
        //   this.card.commission = this.commission
        //   this.card.installment = this.installment
        //   this.card.installment.amount = this.installment_amount
        // }

        // await this.$http.post(process.env.VUE_APP_API_BASE+'/payment_gateway/stripe/' + this.$store.getters['center/currentCenterId'] + '/', this.card)
        //   .then(res => {
        //     if (res.data.result === true) {
        //       this.$toast({
        //       component: ToastificationContent,
        //          position: 'top-center',
        //          props: {
        //            title: 'Success',
        //            icon: 'CheckIcon',
        //            variant: 'success',
        //            text: res.data.message,
        //          },
        //        })
        //       this.stripe_id = res.data.stripe_id
        //       if (res.data.installment.enabled === true) {
        //         this.return_installment = res.data.installment
        //       }
        //       this.card.number = null
        //       this.card.expire = null
        //       this.card.cvc = null
        //       this.card.amount = 0
        //       this.show = false
        //       this.add_payment_2()
        //     } else {
        //       this.$toast({
        //         component: ToastificationContent,
        //         position: 'top-center',
        //         props: {
        //           title: 'Error',
        //           icon: 'XIcon',
        //           variant: 'danger',
        //           text: res.data.message,
        //         },
        //       },
        //       {
        //         timeout:999999,
        //       })
        //       this.show = false
        //     }
        //   })
        break;
        default:
          this.add_payment_2()
        break;

      }


    },
    clear_payment() {
      this.payment.payment_method = [];
      this.payment.amount = 0
    },
    add_payment_2() {

      this.payment.payment_method.push({
        "method" : this.payment.selected_payment_method,
        "amount" : this.payment.payment_amount,
       
      })
      
     
      this.payment.payment_amount = 0
      this.payment.selected_payment_method = null
    
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      console.log(isEmpty)
      console.log(data)
      this.signature = data
      this.upload_signature(data)
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },

    onBegin() {
      console.log('=== Begin ===');
    },
    onEnd() {
      console.log('=== End ===');
    },
    get_pt_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/booking/'+router.currentRoute.params.id+'/')
        .then(res => {
          this.invoiceData = res.data
          this.packageOptions = res.data.packages
          this.onetime_package = res.data.onetime_package
        })
    },
    get_paymentMethods() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/payments/')
        .then(res => {
          this.paymentMethods = res.data
         
        })
    },
    upload_signature(signature) {
      this.$http.post(process.env.VUE_APP_API_BASE+'/package/'+router.currentRoute.params.id+'/signature/', 
        { 
          "signature" : signature,
          "credit" : this.credit,
          "package" : this.invoiceData.selected_package,
          "pay" : this.onetime_pay,
          "payment" : this.payment,
          "onetime_selected_package" : this.onetime_selected_package,
          "center_id" : this.$store.getters['center/currentCenterId']
        }
      )
        .then(res => {
          if (res.data.result === true) {
          this.$router.push('/calendar').catch(() => {})
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  .tnc {
    line-height:2rem;
  }
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
